import {
  Box,
  CustomInput,
  CustomIconButton,
  SendRounded,
  Divider,
} from "components/common";
import LogoIcon from "assets/icons/Logo.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram-icon.svg";
import { ReactComponent as MessageIcon } from "assets/icons/message-icon.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp-icon.svg";
import { TextField } from "@mui/material";
export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#000",
      }}
    >
      <Box
        sx={{
          maxWidth: 1200,
          m: "auto",
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            // width: "250px",
            // height: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={LogoIcon}
            width="200px"
            height="auto"
            alt=""
          />

          <Box
            sx={{
              color: "#fff",
              fontWeight: "450",
              lineHeight: "20px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Box>
          <Box
            sx={{
              pt: 3,
            }}
          >
            <CustomIconButton>
              <FacebookIcon />
            </CustomIconButton>
            <CustomIconButton>
              <InstagramIcon />
            </CustomIconButton>
            <CustomIconButton>
              <WhatsappIcon />
            </CustomIconButton>
            <CustomIconButton>
              <MessageIcon />
            </CustomIconButton>
          </Box>
        </Box>
        <Box
          sx={{
            pt: 3,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Box
                sx={{
                  color: "#0F0",
                  fontSize: "20px",
                  fontWeight: "700",
                  height: "64px",
                }}
              >
                Company
              </Box>
              <Box
                sx={{
                  fontWeight: "450",
                  color: "#E3E3E3",
                  lineHeight: "35px",
                }}
              >
                <Box>Contact Us</Box>
                <Box>News</Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Box
                sx={{
                  color: "#0F0",
                  fontSize: "20px",
                  fontWeight: "700",
                  height: "64px",
                }}
              >
                Policy
              </Box>
              <Box sx={{ fontWeight: "450", color: "#E3E3E3" }}>
                Shipping information
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            pt: 3,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#E3E3E3",
            }}
          >
            Sign Up For News
          </Box>
          <Box
            sx={{
              fontWeight: "400",
              color: "#fff",
            }}
          >
            Keep up to date with the latest product launches and news. <br />{" "}
            Find out more about our brands and get special promo codes.
          </Box>
          <Box>
            <TextField
              margin="none"
              placeholder="Enter Email Address"
              InputProps={{
                endAdornment: (
                  <CustomIconButton>
                    <SendRounded
                      sx={{
                        transform: "rotate(-45deg)",
                      }}
                    />
                  </CustomIconButton>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          p: 3,
          maxWidth: 1200,
          m: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "450",
          }}
        >
          All Right Reserved.
        </Box>
        <Box
          sx={{
            fontSize: "20px",
            fontWeight: "450",
          }}
        >
          SOUNDLABZ 2024
        </Box>
      </Box>
    </Box>
  );
}
