import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  addToLocalStorage,
  localStorageEffect,
  removeFromLocalStorage,
} from "utils/localStorageUtils";

const localStorageKey = "drawer";

const drawerState = atom({
  key: "DrawerState",
  default: false,
  effects: [localStorageEffect(localStorageKey)],
});

const setDrawerAndLocalStorage = (setter: any, newValue: any) => {
  setter(newValue);
  addToLocalStorage(localStorageKey, newValue);
};

export const useDrawerState = () => useRecoilState(drawerState);
export const useSetDrawerState = () => {
  const setDrawer = useSetRecoilState(drawerState);
  return (newValue: any) => {
    console.log(newValue);
    setDrawerAndLocalStorage(setDrawer, newValue);
  };
};
export const useResetDrawerState = () => {
  const resetDrawerState = useResetRecoilState(drawerState);
  return () => {
    resetDrawerState();
    removeFromLocalStorage(localStorageKey);
  };
};
