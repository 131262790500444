import { atom } from "recoil";

export const yearState = atom({
  key: "yearState", // unique ID for the atom
  default: {
    options: [],
    value: null as string | null, // Allowing value to be either string or null
    loading: false,
  },
});

export const makeState = atom({
  key: "makeState",
  default: {
    options: [],
    value: null as string | null, // Allowing value to be either string or null
    loading: false,
  },
});

export const modelState = atom({
  key: "modelState",
  default: {
    options: [],
    value: null as string | null, // Allowing value to be either string or null
    loading: false,
  },
});

export const bulbPositionState = atom({
  key: "bulbPositionState",
  default: {
    options: [],
    value: null as string | null, // Allowing value to be either string or null
    loading: false,
  },
});

export const resultsState = atom({
  key: "resultsState",
  default: {
    show: false,
    total: 0,
    products: [],
    year: 0,
    make: "",
    model: "",
    position: "",
    types: "",
  },
});
