import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import { CSSProperties, SVGProps } from "react";

interface ProjectLogoProps extends SVGProps<SVGSVGElement> {
  style?: CSSProperties;
}

export default function ProjectLogo(props: ProjectLogoProps) {
  const { style } = props;
  return (
    <Logo
      title="Sound Labz - Car Audio Speakers, Subwoofers, Amplifiers and more"
      style={style}
      {...props}
    />
  );
}
