import { lazy } from "react";

export const HomePage = lazy(() => import("pages/home"));
export const ContactPage = lazy(() => import("pages/contact"));
export const AboutUsPage = lazy(() => import("pages/about"));
export const ProductsPage = lazy(() => import("pages/products"));
export const ProductDetailsPage = lazy(() => import("pages/product-details"));
export const CartPage = lazy(() => import("pages/cart"));
export const CheckoutPage = lazy(() => import("pages/checkout"));

export const BecomeDealerIntroPage = lazy(
  () => import("pages/become-dealers/intro")
);
export const BecomeDealerDetailsPage = lazy(
  () => import("pages/become-dealers/details")
);
export const BecomeDealerForm1Page = lazy(
  () => import("pages/become-dealers/form1")
);
export const BecomeDealerForm2Page = lazy(
  () => import("pages/become-dealers/form2")
);
export const BecomeDealerForm3Page = lazy(
  () => import("pages/become-dealers/form3")
);
export const BecomeDealerForm4Page = lazy(
  () => import("pages/become-dealers/form4")
);
export const BecomeDealerForm5Page = lazy(
  () => import("pages/become-dealers/form5")
);
export const BecomeDealerForm6Page = lazy(
  () => import("pages/become-dealers/form6")
);
export const BecomeDealerStatuspage = lazy(
  () => import("pages/become-dealers/status")
);
