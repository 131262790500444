import {
  Box,
  CustomInput,
  CustomIconButton,
  SendRounded,
  Divider,
  Typography,
  CustomLink,
} from "components/common";
import LogoIcon from "assets/icons/Logo.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram-icon.svg";
import { ReactComponent as MessageIcon } from "assets/icons/message-icon.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp-icon.svg";
export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#000",
      }}
    >
      <Box
        sx={{
          maxWidth: 1200,
          m: "auto",
          p: { xs: 2, lg: 3 },
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={LogoIcon}
            width="200px"
            height="auto"
            alt=""
          />

          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "400",
                fontSize: { xs: "14px", sm: "16px", md: "16px", lg: "16px" },
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
          </Box>
          <Box
            sx={{
              pt: 3,
              display: "flex",
              gap: "15px",
            }}
          >
            <CustomIconButton>
              <FacebookIcon />
            </CustomIconButton>
            <CustomIconButton>
              <InstagramIcon />
            </CustomIconButton>
            <CustomIconButton>
              <WhatsappIcon />
            </CustomIconButton>
            <CustomIconButton>
              <MessageIcon />
            </CustomIconButton>
          </Box>
        </Box>
        <Box
          sx={{
            pt: 3,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "20px", sm: "40px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#0F0",
                  fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
                  fontWeight: "700",
                }}
              >
                Company
              </Typography>
              <Box>
                <CustomLink to="contact-us" style={{ width: "100%" }}>
                  Contact Us
                </CustomLink>
                <CustomLink to="about-us">About Us</CustomLink>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#0F0",
                  fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
                  fontWeight: "700",
                }}
              >
                Policy
              </Typography>
              <Box>
                <CustomLink to="contact-us" style={{ width: "100%" }}>
                  Shipping information
                </CustomLink>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            pt: 3,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography
            sx={{
              color: "#E3E3E3",
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
              fontWeight: "700",
            }}
          >
            Sign Up For News
          </Typography>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                color: "#E3E3E3",
                fontSize: {
                  xs: "12px",
                  sm: "16px",
                  md: "16px",
                  lg: "16px",
                },
              }}
            >
              Keep up to date with the latest product launches and news.
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                color: "#E3E3E3",
                fontSize: {
                  xs: "12px",
                  sm: "16px",
                  md: "16px",
                  lg: "16px",
                },
                padding: "5px 0px",
              }}
            >
              Find out more about our brands and get special promo codes.
            </Typography>
          </Box>
          <Box>
            <CustomInput
              margin="none"
              placeholder="Enter Email Address"
              InputProps={{
                endAdornment: (
                  <CustomIconButton>
                    <SendRounded
                      sx={{
                        transform: "rotate(-45deg)",
                      }}
                    />
                  </CustomIconButton>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          p: { xs: 2, lg: 3 },
          maxWidth: 1200,
          m: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
            fontWeight: "400",
            color: "#fff",
          }}
        >
          All Right Reserved.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
            fontWeight: "400",
            color: "#fff",
          }}
        >
          SOUNDLABZ 2024
        </Typography>
      </Box>
    </Box>
  );
}
