import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

const key = "dashboard-loader";

const dashboardLoaderState = atom({
  key: key,
  default: false,
});

export const useDashboardLoaderState = () =>
  useRecoilState(dashboardLoaderState);
export const useSetDashboardLoaderState = () =>
  useSetRecoilState(dashboardLoaderState);
