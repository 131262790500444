import { Box } from "components/common";
import { ReactComponent as Logo } from "assets/icons/Logo.svg";

export default function ScreenLoader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      position="absolute"
      top={0}
      left={0}
      bgcolor="#000000"
      zIndex={100}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <Logo
        width={150}
        style={{
          animation: "1s fadeInOut infinite",
        }}
      />
    </Box>
  );
}
