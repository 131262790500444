import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ForwardedRef, forwardRef, useEffect } from "react";
import { usePathname } from "hooks/use-pathname";
import { Link } from "react-router-dom";
import { theme } from "theme";

interface NavItemProps {
  item: {
    title: string;
    url: string;
    icon: React.ElementType;
    target?: string;
    disabled?: boolean;
  };
  onBulbFinderOpen?: () => void; // Optional prop for opening the modal
}

export default function NavItem({ item, onBulbFinderOpen }: NavItemProps) {
  const pathname = usePathname();

  const itemTarget = item.target ? "_blank" : "_self";
  const drawerOpen = true;
  const isSelected = item.url === pathname;

  type AnchorRef = ForwardedRef<HTMLAnchorElement>;
  let listItemProps = {
    component: forwardRef((props, ref: AnchorRef) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };

  listItemProps.component.displayName = "ListItemLink";

  const itemHandler = () => {
    if (item.title === "Bulb Finder") {
      if (onBulbFinderOpen) onBulbFinderOpen(); // Call the function to open the modal
    }
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const textColor = "#636363 !important";
  const iconSelectedColor = "#fff";

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={itemHandler}
      selected={isSelected}
      sx={{
        my: 1,
        pl: 2,
        py: 1.5,
        gap: 1,
        "&:hover": {
          "& svg": {
            fill: iconSelectedColor,
          },
          "& span": {
            color: iconSelectedColor,
          },
        },
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            fill: textColor,
            "& svg": {
              width: "22px",
              height: "22px",
            },
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}

      <ListItemText
        sx={{
          fontSize: "17px",
        }}
        primary={item.title}
      />
    </ListItemButton>
  );
}
