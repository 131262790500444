import { useMemo } from "react";

import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { drawerWidth } from "config";
import useFunctions from "../ts/useFunctions";
import DrawerContent from "./drawer-content";
import DrawerHeader from "./drawer-header";

interface MainDrawerProps {
  window?: any;
}

export default function MainDrawer(props: MainDrawerProps) {
  const { window } = props;
  const theme = useTheme();
  const { open, handleDrawerToggle } = useFunctions();

  const drawerHeader = useMemo(() => <DrawerHeader />, []);
  const drawerContent = useMemo(() => <DrawerContent />, []);

  return (
    <Drawer
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
      onClose={handleDrawerToggle}
    >
      {drawerHeader}
      {drawerContent}
    </Drawer>
  );
}
