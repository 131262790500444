import "App.css";

import { useScrollToTop } from "hooks/use-scroll-to-top";
import Router from "routes/routes";

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return <Router />;
}
