import React from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const categories = [
  {
    title: "Installation Accessories & Wire",
    subItems: [
      // "HARLEY DAVIDSON",
      // "Z WIRE POWER CABLE - EOL",
      // "AERO PORT",
      // "SUBWOOFER BAR GRILLS",
      // "SPEAKER MESH GRILLS",
    ],
  },
  {
    title: "Signal Processors & Amplifiers",
    subItems: [
      // "LINE OUTPUT CONVERTERS",
      // "DIGITAL SIGNAL PROCESSORS",
      // "SS/SX SERIES - AMPLIFIERS",
      // "AMPLIFIERS - SERIES D",
      // "PREMIUM AMPLIFIERS - SERIES M",
      // "PREMIUM AMPLIFIERS - SERIES Z",
      // "AMPLIFIERS M/Z",
      // "AMPLIFIERS M/Z ACCESSORIES",
    ],
  },
  {
    title: "Data Engineering Services",
    subItems: [
      // "Data Architecture",
      // "Data Pipelines",
      // "Data Analytics",
      // "ML Engineering",
      // "Data Lake Implementation",
      // "Data Integration",
      // "DataOps",
    ],
  },
  {
    title: "Pro Audio Speakers",
    subItems: [
      // "BULLET TWEETERS",
      // "COMPRESSION TWEETERS",
      // "COMPRESSION HORNS",
      // "MIDRANGE SPEAKERS",
      // "MIDRANGE NEO SPEAKERS",
      // "FAIRRITE SPEAKERS",
      // "MID WOOFERS",
      // "VO Tweeter",
    ],
  },
  {
    title: "Power Tuned Subwoofers",
    subItems: [
      // "PSW SERIES EOL",
      // "SERIES 600f",
      // "SERIES 700f",
      // "SERIES 800f",
      // "SERIES 600",
      // "SERIES 700",
      // "SERIES 800",
    ],
  },
  {
    title: "Hi-Def Tuned Subwoofers",
    subItems: [
      // "Loaded Subwoofer Enclosure", "SERIES SW - EOL", "Series SL600"
    ],
  },
  {
    title: "US Built Subwoofers",
    subItems: [
      // "SERIES 1100",
      // "SERIES 1506",
      // "SERIES 2508G",
      // "SERIES 9500L",
      // "SERIES 9900b",
      // "SERIES 9900c",
      // "SERIES Z3",
      // "SERIES Z4",
    ],
  },
  {
    title: "Snowflake Consulting",
    subItems: [
      // "Migration",
      // "Modernization",
      // "Architecture",
      // "Implementation",
      // "Warehousing",
    ],
  },
  {
    title: "Custom Build Options",
    subItems: [
      // "SUPERCHARGING",
      // "SUSPENSION OPTIONS",
      // "LEAD WIRE LENGTH",
      // "POWDER COATED SUBWOOFER FRAMES",
      // "COMPOSITE DUST CAP GLOSS FINISH",
      // "COMPOSITE DUST CAP DRY FINISH",
      // "COMPOSITE CONE",
    ],
  },
  {
    title: "Subwoofer Repair",
    subItems: [
      // "REDLINE RPK", "HI-DEF POWER TUNED Z RPK", "GLUE KITS"
    ],
  },
  {
    id: "coaxialSpeakers",
    title: "Coaxial Speakers",
    subItems: [],
  },
  {
    id: "headphonesPromo",
    title: "Headphones & Promo Gear",
    subItems: [],
  },
];

const AccordionComponent = () => {
  return (
    <Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography sx={{ color: "#000", fontSize: "20px", fontWeight: "500" }}>
          DD Audio
        </Typography>
      </Box>
      <Box sx={{ padding: 4, maxHeight: 500, overflowY: "auto" }}>
        <Grid
          container
          spacing={4}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr 1fr",
            },
            gap: 3,
          }}
        >
          {categories.map((category) => (
            <Box key={category.title}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  padding: "8px 16px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  fontWeight: "500",
                  color: "#333",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#00E852",
                    color: "#fff",
                    padding: "8px 20px", // Increased padding for hover effect
                  },
                }}
              >
                {category.title}
              </Typography>
              <List sx={{ paddingLeft: 2 }}>
                {category.subItems.map((subItem, index) => (
                  <ListItem
                    key={index}
                    disableGutters
                    sx={{
                      paddingY: 0.5,
                      paddingX: 2,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#00E852",
                        color: "#fff",
                        "& .MuiTypography-root": {
                          color: "#fff", // Text color on hover
                        },
                        "& .MuiListItemIcon-root": {
                          color: "#fff", // Icon color on hover
                        },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "auto",
                        marginRight: 1,
                        color: "#00E852",
                        transition: "color 0.3s ease",
                        "&:hover": {
                          color: "#fff",
                        },
                      }}
                    >
                      <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#555",
                        cursor: "pointer",
                      }}
                    >
                      {subItem}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AccordionComponent;
