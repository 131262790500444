// src/components/BulbFinderModal.tsx
import * as React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import {
  ArrowBackIosNewRounded,
  CloseRounded,
  CustomIconButton,
  Grid,
} from "components/common";
import CustomAsyncAutocomplete from "components/common/custom-async-autocomplete";
import useBulbFinderFunctions from "./ts/useBulbFinderFunctions";
import { theme } from "theme";
import { LoadingButton } from "@mui/lab";
import ProductCard from "components/pages/products-page/products/product-list/product-card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

interface BulbFinderModalProps {
  open: boolean;
  onClose: () => void;
}

const BulbFinderModal: React.FC<BulbFinderModalProps> = ({ open, onClose }) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    year,
    make,
    model,
    bulbPosition,
    results,
    fetchYears,
    fetchMakes,
    fetchModels,
    fetchBulbPositions,
    fetchBulbResults,
    resetSelections,
    setYearValue,
    setMakeValue,
    setModelValue,
    setBulbPositionValue,
    btnLoader,
    handleBack,
  } = useBulbFinderFunctions();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
    >
      {!results.show ? (
        <DialogContent sx={{ backgroundColor: "#fff", color: "#000" }}>
          <Box display="flex" alignItems="center" gap={2} mb={2}>
            <Box display={"flex"} alignItems="center" gap={3}>
              <CustomIconButton color="primary" onClick={onClose}>
                <CloseRounded />
              </CustomIconButton>
              <Typography variant="h6">Bulb Finder</Typography>
            </Box>
          </Box>

          <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3 }} width="100%">
            <Grid xs={12} sm={6} mb={2} md={3} lg={3}>
              <Typography variant="body2" sx={{ color: "#000", mb: 1 }}>
                Year
              </Typography>
              <CustomAsyncAutocomplete
                label="Select Year"
                options={year.options}
                loading={year.loading}
                value={year.value}
                onChange={(event, newValue) => {
                  setYearValue(newValue);
                  resetSelections("year");
                  fetchMakes();
                }}
                getOptionLabel={(option) => option}
                onOpen={fetchYears}
              />
            </Grid>

            <Grid xs={12} sm={6} mb={2} md={3} lg={3}>
              <Typography variant="body2" sx={{ color: "#000", mb: 1 }}>
                Make
              </Typography>
              <CustomAsyncAutocomplete
                label="Select Make"
                options={make.options}
                loading={make.loading}
                value={make.value}
                onChange={(event, newValue) => {
                  setMakeValue(newValue);
                  resetSelections("make");
                  fetchModels();
                }}
                getOptionLabel={(option) => option}
                disabled={!year.value}
                onOpen={fetchMakes}
              />
            </Grid>

            <Grid xs={12} sm={6} mb={2} md={3} lg={3}>
              <Typography variant="body2" sx={{ color: "#000", mb: 1 }}>
                Model
              </Typography>
              <CustomAsyncAutocomplete
                label="Select Model"
                options={model.options}
                loading={model.loading}
                value={model.value}
                onChange={(event, newValue) => {
                  setModelValue(newValue);
                  resetSelections("model");
                  fetchBulbPositions();
                }}
                getOptionLabel={(option) => option}
                disabled={!make.value}
                onOpen={fetchModels}
              />
            </Grid>

            <Grid xs={12} sm={6} mb={2} md={3} lg={3}>
              <Typography variant="body2" sx={{ color: "#000", mb: 1 }}>
                Bulb Position
              </Typography>
              <CustomAsyncAutocomplete
                label="Select Bulb Position"
                options={bulbPosition.options}
                loading={bulbPosition.loading}
                value={bulbPosition.value}
                onChange={(event, newValue) => {
                  setBulbPositionValue(newValue);
                }}
                getOptionLabel={(option) => option}
                disabled={!model.value}
                onOpen={fetchBulbPositions}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" mt={2}>
            <LoadingButton
              loading={btnLoader}
              variant="contained"
              onClick={fetchBulbResults}
              disabled={!bulbPosition.value}
            >
              Find Bulb
            </LoadingButton>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent sx={{ color: "black" }}>
          <Box display={"flex"} alignItems="center" gap={3}>
            <CustomIconButton color="primary" onClick={handleBack}>
              <ArrowBackIosNewRounded />
            </CustomIconButton>
            <Typography variant="h6">Bulb Finder</Typography>
          </Box>
          <Box display={"flex"}>
            <Box pt={3} width="30%">
              <Typography color={theme.palette.primary.main} pb={3}>
                Showing Results For:
              </Typography>
              <Typography color="black">
                Year:&nbsp;
                <Typography component="span" color={theme.palette.primary.main}>
                  {results.year}
                </Typography>
              </Typography>
              <Typography color="black">
                Make:&nbsp;
                <Typography component="span" color={theme.palette.primary.main}>
                  {results.make}
                </Typography>
              </Typography>
              <Typography color="black">
                Model:&nbsp;
                <Typography component="span" color={theme.palette.primary.main}>
                  {results.model}
                </Typography>
              </Typography>
              <Typography color="black">
                Position:&nbsp;
                <Typography component="span" color={theme.palette.primary.main}>
                  {results.position}
                </Typography>
              </Typography>
              <Typography color="black">
                Type:&nbsp;
                <Typography component="span" color={theme.palette.primary.main}>
                  {results.types}
                </Typography>
              </Typography>
            </Box>

            <SwiperWrapper
              slidesPerView={1}
              spaceBetween={10}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }}
              modules={[Navigation]}
            >
              {results.products.map((product: any, index: any) => (
                <>
                  <SwiperSlide key={index}>
                    <ProductCard key={index} data={product} />
                  </SwiperSlide>
                </>
              ))}
              {/* {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <SliderCard data={item} />
                </SwiperSlide>
              ))} */}
            </SwiperWrapper>
            {/* <Grid container spacing={{ xl: 4, lg: 3, md: 2 }}>
              {results.products.map((product: any, index: any) => (
                <ProductCard key={index} data={product} />
              ))}
            </Grid> */}
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default BulbFinderModal;

const SwiperWrapper = styled(Swiper)(({ theme }) => ({
  width: "70%",
  "& .swiper-button-next, .swiper-button-prev": {
    //   "--swiper-navigation-sides-offset": "0px",
    width: 35,
    height: 35,
    backgroundColor: "#D9D9D9",
    borderRadius: 10,
    // "--swiper-navigation-sides-offset": "px",

    "&:after": {
      "--swiper-navigation-size": "10px",
      fontWeight: "bold",
      color: "black",
    },
  },
}));
