import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { localStorageEffect } from "utils/localStorageUtils";

const localStorageKey = "dss";

const dealerSwitchState = atom({
  key: localStorageKey,
  default: false,
  effects: [localStorageEffect(localStorageKey)],
});

export const useDealerSwitchState = () => useRecoilState(dealerSwitchState);
export const useSetDealerSwitchState = () =>
  useSetRecoilState(dealerSwitchState);
export const useResetDealerSwitchState = () =>
  useResetRecoilState(dealerSwitchState);
