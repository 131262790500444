import {
  Box,
  Typography,
  Paper,
  IconButton,
  ListItemIcon,
  ListItem,
  List,
  Grid,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"; // Small bullet point icon

const categories = [
  {
    id: "midrange_loudspeaker",
    title: "Midrange Loudspeaker",
    subItems: [],
  },
  {
    id: "accessories",
    title: "Accessories",
    subItems: [],
  },
  {
    id: "amplifier",
    title: "Amplifier",
    subItems: [],
  },
  {
    id: "battery",
    title: "Battery",
    subItems: [],
  },
  {
    id: "speaker",
    title: "Speaker",
    subItems: [],
  },
  {
    id: "subwoofer",
    title: "Subwoofer",
    subItems: [],
  },
  {
    id: "tweeter",
    title: "Tweeter",
    subItems: [],
  },
];

const AccordionNemisis = () => {
  return (
    <Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography sx={{ color: "#000", fontSize: "20px", fontWeight: "500" }}>
          Bulb Finder
        </Typography>
      </Box>
      <Box sx={{ padding: 4, maxHeight: 500, overflowY: "auto" }}>
        <Grid
          container
          spacing={4}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr 1fr",
            },
            gap: 3,
          }}
        >
          {categories.map((category) => (
            <Box key={category.title}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  padding: "8px 16px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  fontWeight: "500",
                  color: "#333",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#00E852",
                    color: "#fff",
                    padding: "8px 20px", // Increased padding for hover effect
                  },
                }}
              >
                {category.title}
              </Typography>
              <List sx={{ paddingLeft: 2 }}>
                {category.subItems.map((subItem, index) => (
                  <ListItem
                    key={index}
                    disableGutters
                    sx={{
                      paddingY: 0.5,
                      paddingX: 2,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#00E852",
                        color: "#fff",
                        "& .MuiTypography-root": {
                          color: "#fff", // Text color on hover
                        },
                        "& .MuiListItemIcon-root": {
                          color: "#fff", // Icon color on hover
                        },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "auto",
                        marginRight: 1,
                        color: "#00E852",
                        transition: "color 0.3s ease",
                      }}
                    >
                      <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
                    </ListItemIcon>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#555",
                        cursor: "pointer",
                      }}
                    >
                      {subItem}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AccordionNemisis;
