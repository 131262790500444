import {
  AppBar,
  Avatar,
  Box,
  CustomIconButton,
  CustomInput,
  CustomLink,
  MuiLoadingButton,
  FavoriteRounded,
  HideOnScroll,
  LocalMallRounded,
  MenuOpenRounded,
  MenuRounded,
  MuiBadge,
  ProjectLogo,
  SearchRounded,
  Toolbar,
  useMediaQuery,
  Collapse,
  CustomIOSSwitch,
} from "components/common";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "theme";
import useFunctions from "../ts/useFunctions";
import { CircularProgress, FormControlLabel } from "@mui/material";
import HeaderDesktopLinks from "layouts/main-layout/header/header-desktop-links";

export default function Header() {
  const navigate = useNavigate();
  const {
    open,
    dealerSwitch,
    user,
    userApiLoader,
    cart,
    handleDrawerToggle,
    getUser,
    handleDealerSwitchToggle,
    setHeaderHeight,
  } = useFunctions();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const [showSearch, setShowSearch] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const handleMobileSearchToggle = () => {
    setShowMobileSearch((prev) => !prev);
  };
  const handleSearchToggle = () => {
    setShowSearch((prev) => !prev);
  };

  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      console.log(headerRef.current.clientHeight);
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [setHeaderHeight]);

  const handleBecomeDealer = () => {
    navigate("/become-dealer");
  };

  const HeaderSearchInput = () => (
    <CustomInput
      placeholder="Search"
      InputProps={{
        endAdornment: (
          <CustomIconButton>
            <SearchRounded />
          </CustomIconButton>
        ),
      }}
      margin="none"
      style={{ width: matchDownMD ? "100%" : 400 }}
    />
  );
  return (
    <HideOnScroll>
      <AppBar
        ref={headerRef}
        variant="outlined"
        sx={{
          zIndex: 10,
          width: "100%",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            height: matchDownMD ? "80px" : "100px",
            justifyContent: "center",
            backgroundColor: "black",
            minHeight: "0 !important",
          }}
        >
          <Box
            width="100%"
            maxWidth={1200}
            margin="auto"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {matchDownMD && (
                <CustomIconButton
                  disableRipple
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  color="secondary"
                  sx={{
                    transitionProperty: "all",
                    transitionDuration: "225ms",
                    TransitionTimingFunction: "cubic-bezier(0.4,0,0.6,1)",
                  }}
                >
                  {!open ? <MenuRounded /> : <MenuOpenRounded />}
                </CustomIconButton>
              )}
              <CustomLink to="/">
                <ProjectLogo style={{ width: 125 }} />
              </CustomLink>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                {!matchDownMD && (
                  <Collapse in={showSearch} timeout={1000}>
                    <HeaderSearchInput />
                  </Collapse>
                )}
                <CustomIconButton
                  color="secondary"
                  onClick={
                    matchDownMD ? handleMobileSearchToggle : handleSearchToggle
                  }
                >
                  <SearchRounded
                    sx={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </CustomIconButton>
                <CustomIconButton
                  color="secondary"
                  onClick={() => navigate("/cart")}
                >
                  <MuiBadge badgeContent={cart.items.length} color="primary">
                    <LocalMallRounded
                      sx={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </MuiBadge>
                </CustomIconButton>
                {userApiLoader ? (
                  <CircularProgress />
                ) : (
                  <Fragment>
                    <CustomIconButton
                      color="secondary"
                      sx={{ padding: "0 !important" }}
                    >
                      <Avatar
                        sx={{
                          width: "46px",
                          height: "46px",
                        }}
                      >
                        {user.first_name.charAt(0) + user.last_name.charAt(0)}
                      </Avatar>
                    </CustomIconButton>
                    {user.isDealer &&
                    user?.dealerDetails.progress === "approved" ? (
                      <CustomIOSSwitch
                        label={
                          dealerSwitch ? (
                            <>
                              Shopping as <br />
                              Dealer
                            </>
                          ) : (
                            <>
                              Shopping as <br />
                              Retail
                            </>
                          )
                        }
                        checked={dealerSwitch}
                        onChange={handleDealerSwitchToggle}
                      />
                    ) : (
                      <MuiLoadingButton onClick={handleBecomeDealer}>
                        Become a Dealer
                      </MuiLoadingButton>
                    )}
                  </Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Toolbar>

        {matchDownMD ? (
          <Box sx={{ position: "absolute", top: 80, width: "100%" }}>
            <Collapse in={showMobileSearch} timeout={1000}>
              <Toolbar
                sx={{
                  py: 2,
                  justifyContent: "center",
                  backgroundColor: "black",
                  minHeight: "0 !important",
                }}
              >
                <HeaderSearchInput />
              </Toolbar>
            </Collapse>
          </Box>
        ) : (
          <HeaderDesktopLinks />
        )}
      </AppBar>
    </HideOnScroll>
  );
}
