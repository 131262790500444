import { useSearchParams } from "react-router-dom";
import axiosInstance from "utils/axios";
import {
  useDefaultSortState,
  useFilterDrawerState,
  useLoadingState,
  useProductsState,
  useResetProductsState,
  useSetFilterDrawerState,
} from "./states";
import { MouseEvent, useEffect, useState } from "react";
import useInfiniteScroll from "./useInfiniteScroll";

export default function useProductsFunctions() {
  const [searchParams] = useSearchParams();
  const brand = searchParams.get("brand");
  const category = searchParams.get("category");

  const [open] = useFilterDrawerState();
  const setOpen = useSetFilterDrawerState();
  const [loading, setLoading] = useLoadingState();
  const [data, setData] = useProductsState();
  const dataReset = useResetProductsState();
  const [sort, setSort] = useDefaultSortState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const lastElementRef = useInfiniteScroll(loading, hasMore, () =>
    getProducts(sort.value, nextCursor || "")
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterDrawerToggle = () => {
    setOpen(!open);
  };

  const getProducts = async (sortOrder?: string, cursor?: string) => {
    if (loading || !hasMore) return;
    setLoading(true);

    if (!cursor) {
      dataReset();
    }

    const url = category
      ? `/products/category?name=${category}&sortOrder=${sortOrder}`
      : cursor
      ? `/products?startCursor=${cursor}&sortOrder=${sortOrder}`
      : `/products?sortOrder=${sortOrder}`;

    try {
      const response = await axiosInstance.get(url);
      const { products, nextCursor: newCursor } = response.data;

      setData((prevData) => [...(prevData || []), ...products]);
      setNextCursor(newCursor);
    } catch (err: any) {
      if (err.response.status === 416) {
        setHasMore(false);
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = async (option: { value: string; label: string }) => {
    setSort(option);
    setData([]);
    handleClose();
    setNextCursor(null);
    setHasMore(true);
    getProducts(sort.value);
  };

  return {
    loading,
    category,
    brand,
    open,
    setOpen,
    data,
    getProducts,
    handleFilterDrawerToggle,
    sort,
    handleSort,
    handleClick,
    menuOpen,
    anchorEl,
    handleClose,
    nextCursor,
    hasMore,
    lastElementRef,
  };
}
