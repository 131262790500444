import * as React from "react";
import {
  Autocomplete,
  CircularProgress,
  Paper,
  TextField,
} from "@mui/material";

interface CustomAsyncAutocompleteProps {
  label: string;
  options: string[];
  loading: boolean;
  value: string | null;
  onChange: (event: React.SyntheticEvent, newValue: string | null) => void;
  getOptionLabel: (option: string) => string;
  disabled?: boolean;
  onOpen?: () => void;
}

const CustomAsyncAutocomplete: React.FC<CustomAsyncAutocompleteProps> = ({
  label,
  options,
  loading,
  value,
  onChange,
  getOptionLabel,
  disabled = false,
  onOpen,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    setOpen(true);
    if (onOpen) {
      await onOpen();
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      disabled={disabled}
      PaperComponent={(params) => (
        <Paper
          {...params}
          sx={{ bgcolor: "#e0e0e0 !important", color: "black" }}
        >
          {params.children}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="filled" margin="normal" />
      )}
    />
  );
};

export default CustomAsyncAutocomplete;
