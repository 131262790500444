import {
  MuiLoadingButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  Formik,
} from "components/common";
import { Fragment } from "react";

import { EmailAddress, Password } from "./AuthInputs";
import useAuthFunctions from "./ts/useAuthFunctions";

export default function LoginForm() {
  const { initialLoginValues, validationLoginSchema, setTab, onLoginSubmit } =
    useAuthFunctions();

  return (
    <Fragment>
      <DialogContent>
        <DialogTitle
          sx={{
            color: "#666",
            fontSize: "36px",
          }}
        >
          Login
        </DialogTitle>
        <Formik
          initialValues={initialLoginValues}
          validationSchema={validationLoginSchema}
          onSubmit={onLoginSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContentText>
                Please login using account details below.
              </DialogContentText>
              <EmailAddress />
              <Password />
              <DialogContentText
                sx={{
                  color: "#373737",
                  cursor: "pointer",
                }}
              >
                Forgot your password?
              </DialogContentText>
              <DialogActions
                sx={{
                  flexDirection: "column",
                }}
              >
                <MuiLoadingButton
                  variant="contained"
                  loading={isSubmitting}
                  type="submit"
                  sx={{
                    width: "100%",

                    marginBottom: "10px",
                  }}
                >
                  Login
                </MuiLoadingButton>
                <DialogContentText>
                  Don't have an account?
                  <MuiLoadingButton
                    variant="text"
                    onClick={() => setTab(1)}
                    color="primary"
                  >
                    Sign Up Now
                  </MuiLoadingButton>
                </DialogContentText>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Fragment>
  );
}
