import { Box, ScreenLoader } from "components/common";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import Header from "./header";
import useFunctions from "./ts/useFunctions";
import Footer from "./footer";

export default function AuthedLayout() {
  const { loader, headerHeight } = useFunctions();

  return (
    <>
      <Header />
      <Box
        sx={{
          width: "100%",
          maxWidth: 2000,
          margin: "auto",
          mt: `${headerHeight}px`,
        }}
      >
        <Suspense fallback={<ScreenLoader />}>
          <Outlet />
        </Suspense>
        <Footer />
        {/* <ScreenLoader /> */}
      </Box>
    </>
  );
}
