import React from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// Define types for sub-items and categories
type SubItem = string | { title: string; subItems: string[] };
type Category = {
  id: string;
  title: string;
  subItems: SubItem[];
};

const categories: Category[] = [
  {
    id: "amplifiers",
    title: "SUNDOWN AMPLIFIERS",
    subItems: [],
  },
  {
    id: "speakers",
    title: "SUNDOWN SPEAKERS",
    subItems: [
      // "PRO AUDIO"
    ],
  },
  {
    id: "subwoofers",
    title: "SUNDOWN SUBWOOFERS",
    subItems: [],
  },
  {
    id: "Wire & Accessories",
    title: "Wire & Accessories",
    subItems: [
      // "Batteries",
      // "WIRE",
      // "ACCESSORIES",
      // "INTERCONNECTS",
      // "INTERCONNECTS CONT",
      // "POWERSPORTS SUBWOOFERS",
      // "SUNDOWN POWERSPORTS SPEAKERS",
      // "POWERSPORTS AMPLIFIERS",
      // "POWERSPORTS BATTERIES",
      // {
      //   title: "POWER KITS",
      //   subItems: [
      //     "1/0AWG WIRE KITS",
      //     "4AWG WIRE KITS",
      //     "8AWG WIRE KITS",
      //     "BIG 3 WIRE KITS",
      //   ],
      // },
      // {
      //   title: "WIRE ROLLS",
      //   subItems: ["1/0 AWG OFC", "1/0 AWG CCA"],
      // },
      // {
      //   title: "ROLLS CONT",
      //   subItems: ["4 AWG OFC", "8 AWG OFC", "REMOTE WIRE"],
      // },
      // {
      //   title: "SPEAKER WIRE",
      //   subItems: [
      //     "8 AWG OFC",
      //     "12 AWG OFC",
      //     "16 AWG OFC",
      //     "SPEAKER WIRE INTERCONNECTS",
      //   ],
      // },
      // {
      //   title: "HEAT SHRINK",
      //   subItems: [
      //     "1/0 AWG HEAT SHRINK",
      //     "4 AWG HEAT SHRINK",
      //     "8 AWG HEAT SHRINK",
      //     "FUSES",
      //     "RING TERMINALS",
      //   ],
      // },
    ],
  },
];

const AccordionComponent = () => {
  const renderSubItems = (subItems: SubItem[]) => {
    return subItems.map((subItem, index) =>
      typeof subItem === "string" ? (
        <ListItem
          key={index}
          disableGutters
          sx={{
            paddingY: 0.5,
            paddingX: 2,
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "#00E852",
              color: "#fff",
              "& .MuiTypography-root": {
                color: "#fff",
              },
              "& .MuiListItemIcon-root": {
                color: "#fff",
              },
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "auto",
              marginRight: 1,
              color: "#00E852",
              transition: "color 0.3s ease",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            <FiberManualRecordIcon sx={{ fontSize: "8px" }} />
          </ListItemIcon>
          <Typography
            sx={{ fontSize: "13px", color: "#555", cursor: "pointer" }}
          >
            {subItem}
          </Typography>
        </ListItem>
      ) : (
        // powerkit
        <Box key={index} sx={{ paddingLeft: 2 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#555",
              // marginBottom: 1,
              fontSize: "15px",
              padding: "5px 0px",
            }}
          >
            {subItem.title}
          </Typography>
          <List sx={{ paddingLeft: 2 }}>
            {renderSubItems(subItem.subItems)}
          </List>
        </Box>
      )
    );
  };

  return (
    <Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography sx={{ color: "#000", fontSize: "20px", fontWeight: "500" }}>
          Bulb Finder
        </Typography>
      </Box>
      <Box sx={{ padding: 4, maxHeight: 500, overflowY: "auto" }}>
        <Grid
          container
          spacing={4}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr 1fr",
            },
            gap: 3,
          }}
        >
          {categories.map((category) => (
            <Box key={category.title}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  padding: "8px 16px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  fontWeight: "500",
                  color: "#333",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#00E852",
                    color: "#fff",
                    padding: "8px 20px",
                  },
                }}
              >
                {category.title}
              </Typography>
              <List sx={{ paddingLeft: 2 }}>
                {renderSubItems(category.subItems)}
              </List>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AccordionComponent;
