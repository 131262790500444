import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { localStorageEffect } from "utils/localStorageUtils";

const localStorageKey = "soundlabz-cart";

interface Product {
  id: number;
  title: string;
  price: number;
  quantity: number;
  subtotal: number;
  data: any[];
}

interface CartItem {
  total: number;
  items: Product[];
}

export const cartState = atom<CartItem>({
  key: "cartState",
  default: { total: 0, items: [] },
  effects: [localStorageEffect(localStorageKey)],
});

export const useCartDataState = () => useRecoilState(cartState);
export const useSetCartDataState = () => useSetRecoilState(cartState);
export const useResetCartDataState = () => useResetRecoilState(cartState);
