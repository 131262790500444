import { Box, Collapse, Paper, Toolbar } from "@mui/material";
import {
  DropDownProps,
  navbarOptions,
  NavbarOptionsProps,
} from "layouts/main-layout/ts/constants";
import { useState } from "react";
import HeaderDesktopLinksItem from "./header-desktop-links-item";

export default function HeaderDesktopLinks() {
  const dropDownInitial = {
    open: false,
    index: 0,
    children: <></>,
  };
  const [dropDown, setDropDown] = useState<DropDownProps>(dropDownInitial);

  const handleDropDownToggle = (data: NavbarOptionsProps, index: number) => {
    setDropDown((prev) => {
      if (prev.index === index) {
        return dropDownInitial;
      } else {
        return {
          open: true,
          index: index,
          children: data.children,
        };
      }
    });
  };
  return (
    <Toolbar
      sx={{
        margin: "auto",
        maxWidth: 1500,
        justifyContent: "center",
        background: "white",
        columnGap: 5,
        rowGap: 1,
        py: 2,
        px: 3,
        minHeight: "0 !important",
      }}
    >
      {navbarOptions.map((item, index) => (
        <HeaderDesktopLinksItem
          key={index}
          item={item}
          index={index}
          handleDropDownToggle={handleDropDownToggle}
        />
      ))}
      <Box
        sx={{
          position: "absolute",
          top: 70,
          width: "100%",
        }}
      >
        <Collapse in={dropDown.open} timeout={200}>
          <Paper
            sx={{
              py: 2,
              px: 3,
              justifyContent: "center",
              color: "black",
              minHeight: "0 !important",
              backgroundColor: "#f9f9f9",
              boxShadow: 3,
            }}
          >
            {dropDown.children}
          </Paper>
        </Collapse>
      </Box>
    </Toolbar>
  );
}
