import { useRecoilState, useResetRecoilState } from "recoil";
import {
  yearState,
  makeState,
  modelState,
  bulbPositionState,
  resultsState,
} from "./atoms";
import axiosInstance from "utils/axios";
import { useState } from "react";

const useBulbFinderFunctions = () => {
  const [year, setYear] = useRecoilState(yearState);
  const [make, setMake] = useRecoilState(makeState);
  const [model, setModel] = useRecoilState(modelState);
  const [bulbPosition, setBulbPosition] = useRecoilState(bulbPositionState);
  const [results, setResults] = useRecoilState(resultsState);
  const resetResults = useResetRecoilState(resultsState);
  const [btnLoader, setBtnLoader] = useState(false);

  const fetchYears = async () => {
    if (year.options.length === 0) {
      setYear((prev) => ({ ...prev, loading: true }));
      try {
        const response = await axiosInstance.get("/bulb-finder/years");
        if (response.data.success) {
          setYear({ options: response.data.data, value: null, loading: false });
        }
      } catch (error) {
        console.error("Failed to fetch year options:", error);
        setYear((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const fetchMakes = async () => {
    if (year.value) {
      setMake((prev) => ({ ...prev, loading: true }));
      try {
        const response = await axiosInstance.get(
          `/bulb-finder/makes?year=${year.value}`
        );
        if (response.data.success) {
          setMake({ options: response.data.data, value: null, loading: false });
        }
      } catch (error) {
        console.error("Failed to fetch make options:", error);
        setMake((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const fetchModels = async () => {
    if (make.value) {
      setModel((prev) => ({ ...prev, loading: true }));
      try {
        const response = await axiosInstance.get(
          `/bulb-finder/models?year=${year.value}&make=${make.value}`
        );
        if (response.data.success) {
          setModel({
            options: response.data.data,
            value: null,
            loading: false,
          });
        }
      } catch (error) {
        console.error("Failed to fetch model options:", error);
        setModel((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const fetchBulbPositions = async () => {
    if (model.value) {
      setBulbPosition((prev) => ({ ...prev, loading: true }));
      try {
        const response = await axiosInstance.get(
          `/bulb-finder/positions?year=${year.value}&make=${make.value}&model=${model.value}`
        );
        if (response.data.success) {
          setBulbPosition({
            options: response.data.data,
            value: null,
            loading: false,
          });
        }
      } catch (error) {
        console.error("Failed to fetch bulb position options:", error);
        setBulbPosition((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const fetchBulbResults = async () => {
    if (year.value && make.value && model.value && bulbPosition.value) {
      setBtnLoader(true);
      try {
        const response = await axiosInstance.post("/bulb-finder/results", {
          year: year.value,
          make: make.value,
          model: model.value,
          position: bulbPosition.value,
        });
        if (response.data.success) {
          setResults({ show: true, ...response.data });
        }
      } catch (error) {
        console.error("Failed to fetch bulb results:", error);
      } finally {
        setBtnLoader(false);
      }
    }
  };

  const resetSelections = (field: string) => {
    switch (field) {
      case "year":
        setMake({ options: [], value: null, loading: false });
        setModel({ options: [], value: null, loading: false });
        setBulbPosition({ options: [], value: null, loading: false });
        break;
      case "make":
        setModel({ options: [], value: null, loading: false });
        setBulbPosition({ options: [], value: null, loading: false });
        break;
      case "model":
        setBulbPosition({ options: [], value: null, loading: false });
        break;
      default:
        break;
    }
  };

  // Create setter functions for dropdown values
  const setYearValue = (value: string | null) =>
    setYear((prev) => ({ ...prev, value }));
  const setMakeValue = (value: string | null) =>
    setMake((prev) => ({ ...prev, value }));
  const setModelValue = (value: string | null) =>
    setModel((prev) => ({ ...prev, value }));
  const setBulbPositionValue = (value: string | null) =>
    setBulbPosition((prev) => ({ ...prev, value }));

  const handleBack = () => {
    resetResults();
  };

  return {
    year,
    make,
    model,
    bulbPosition,
    results,
    fetchYears,
    fetchMakes,
    fetchModels,
    fetchBulbPositions,
    fetchBulbResults,
    resetSelections,
    setYearValue,
    setMakeValue,
    setModelValue,
    setBulbPositionValue,
    btnLoader,
    setBtnLoader,
    handleBack,
  };
};

export default useBulbFinderFunctions;
