import LoaderImage from "assets/gif/bg-card-loader.gif";
import {
  Box,
  MuiLoadingButton,
  Grid,
  LocalMallRounded,
  Typography,
} from "components/common";
import useProductsFunctions from "components/pages/products-page/ts/useProductsFunctions";

import { useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import { ProductCardWrapper } from "./index.styled";
import useCartFunctions from "hooks/use-cart";
import useFunctions from "layouts/authed-layout/ts/useFunctions";

const ProductCard = forwardRef<HTMLDivElement, any>((props: any, ref) => {
  const { data } = props;
  const { id, title, thumbnail, images, retail_price, wholesale_price } = data;
  const { open } = useProductsFunctions();
  const { addToCart } = useCartFunctions();
  const { dealerSwitch } = useFunctions();

  const [imgLoading, setImgLoading] = useState(true);
  const handleImageLoad = () => {
    setImgLoading(false);
  };

  return (
    <ProductCardWrapper
      ref={ref}
      imgLoading={imgLoading}
      xl={open ? 4 : 3}
      lg={open ? 4 : 3}
      md={open ? 4 : 3}
      sm={4}
      xs={12}
    >
      <Box className="main">
        <Box>
          <Box className="img-container">
            <Box
              component="img"
              src={images[0]}
              alt={images[0]}
              onLoad={handleImageLoad}
            />
          </Box>
          <Box
            className="title-link"
            component={(props: any) => (
              <Link
                to={`/product-details/${id}`}
                style={{ textDecoration: "none" }}
              >
                {props.children}
              </Link>
            )}
            sx={{
              mt: 2,
              cursor: "pointer",
              "&:hover .title": {
                textDecoration: "underline",
              },
            }}
          >
            <Box>
              <Typography className="title" fontSize="16px" variant="subtitle2">
                {title}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#00E852",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              ${dealerSwitch ? wholesale_price : retail_price}
            </Typography>
          </Box>
        </Box>
        <MuiLoadingButton
          startIcon={<LocalMallRounded />}
          sx={{
            width: "100%",
            mt: 2,
          }}
          onClick={() =>
            addToCart({
              id,
              price: dealerSwitch ? wholesale_price : retail_price,
              title,
              data,
            })
          }
        >
          Add to cart
        </MuiLoadingButton>
      </Box>
    </ProductCardWrapper>
  );
});

export default ProductCard;
