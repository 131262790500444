import { useMediaQuery } from "@mui/material";
import { Dialog } from "components/common";
import useFunctions from "layouts/main-layout/ts/useFunctions";
import * as React from "react";
import { theme } from "theme";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import useAuthFunctions from "./ts/useAuthFunctions";

export default function LoginDialog() {
  const { loginDialogOpen, handleLoginDialogToggle } = useFunctions();
  const { tab } = useAuthFunctions();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Dialog
        open={loginDialogOpen.open}
        onClose={() => handleLoginDialogToggle()}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "600px",
          },
        }}
      >
        {tab === 0 ? <LoginForm /> : <SignupForm />}
      </Dialog>
    </React.Fragment>
  );
}
