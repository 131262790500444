import { Box, Grid, Paper, ProjectLogo } from "components/common";
import { Outlet, useNavigate } from "react-router-dom";
import { BecomeDealerLayoutWrapper } from "./index.styled";
import { Suspense, useEffect } from "react";
import ProjectLogo2 from "components/project-logo/ProjectLogo2";
import useBecomeDealerFunctions from "./ts/useBecomeDealerFunctions";

export default function BecomeDealerLayout() {
  const navigate = useNavigate();
  const { isLoading, getDealerState } = useBecomeDealerFunctions();

  useEffect(() => {
    getDealerState();
  }, []);

  const Loader = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <ProjectLogo2
        width={80}
        style={{
          animation: "1s fadeInOut infinite",
        }}
      />
    </Box>
  );

  return (
    <BecomeDealerLayoutWrapper>
      <Box className="nav">
        <Box className="left">
          <ProjectLogo
            className="logo"
            onClick={() => navigate("/")}
            style={{}}
          />
        </Box>
      </Box>
      <Grid container component="main" className="grid-cont">
        <Grid
          className="left"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          component={Paper}
          elevation={6}
          square
        >
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "calc(100% - 100px)",
                background: "black",
                zIndex: 10,
              }}
            >
              <Loader />
            </Box>
          )}
        </Grid>
        <Grid
          className="right"
          md={6}
          lg={6}
          display={{ xs: "none", md: "block" }}
        />
      </Grid>
    </BecomeDealerLayoutWrapper>
  );
}
