import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { IconButton, IconButtonProps } from "@mui/material";
import {
  CustomIconButtonProps,
  CustomLinkButtonProps,
  CustomLinkProps,
} from "utils/interface";
import { Link, useNavigate } from "react-router-dom";
import { CustomLinkWrapper } from "./CustomButton.styled";

export function CustomLinkButton(props: CustomLinkButtonProps) {
  const { className = "", children, href } = props;

  return (
    <LoadingButton
      {...props}
      className={`custom-button ${className}`}
      // href={href}
      LinkComponent={(linkProps) => (
        <Link to={href} {...linkProps}>
          {linkProps.children}
        </Link>
      )}
    >
      {children}
    </LoadingButton>
  );
}

export function CustomIconButton(props: CustomIconButtonProps) {
  const { className = "", children, sx } = props;
  return (
    <IconButton {...props} className={className}>
      {children}
    </IconButton>
  );
}

export function CustomLink(props: CustomLinkProps) {
  const { className = "", to } = props;
  return (
    <CustomLinkWrapper {...props} to={to}>
      {props.children}
    </CustomLinkWrapper>
  );
}
