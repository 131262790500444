import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  localStorageEffect,
  removeFromLocalStorage,
} from "utils/localStorageUtils";

const localStorageKey = "filter-toggle";

const filterDrawerState = atom({
  key: "filterDrawerState",
  default: false,
  effects: [localStorageEffect(localStorageKey)],
});
const productsState = atom<any[]>({
  key: "productsState",
  default: [],
});
const defaultSortState = atom({
  key: "sortState",
  default: {
    value: "default",
    label: "Default sorting",
  },
});

const loadingState = atom({
  key: "productLoadingState",
  default: false,
});
const setFilterDrawerAndLocalStorage = (setter: any, newValue: any) => {
  setter(newValue);
  localStorage.setItem(localStorageKey, JSON.stringify(newValue));
};
//filterstate
export const useFilterDrawerState = () => useRecoilState(filterDrawerState);
export const useSetFilterDrawerState = () => {
  const setDrawer = useSetRecoilState(filterDrawerState);
  return (newValue: any) => {
    setFilterDrawerAndLocalStorage(setDrawer, newValue);
  };
};
export const useResetFilterDrawerState = () => {
  const resetFilterDrawerState = useResetRecoilState(filterDrawerState);
  return () => {
    resetFilterDrawerState();
    removeFromLocalStorage(localStorageKey);
  };
};
//datastate
export const useProductsState = () => useRecoilState(productsState);
export const useSetProductsState = () => useSetRecoilState(productsState);
export const useResetProductsState = () => useResetRecoilState(productsState);
//sortedstate
export const useDefaultSortState = () => useRecoilState(defaultSortState);
export const useSetDefaultSortState = () =>
  useSetRecoilState(defaultSortState);
export const useResetDefaultSortState = () =>
  useResetRecoilState(defaultSortState);
//loadingState
export const useLoadingState = () => useRecoilState(loadingState);
export const useSetLoadingState = () => useSetRecoilState(loadingState);
export const useResetLoadingState = () => useResetRecoilState(loadingState);
