import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  localStorageEffect,
  removeFromLocalStorage,
} from "utils/localStorageUtils";

const localStorageKey = "soundlabz-auth";

const authDataState = atom({
  key: "AuthDataState",
  default: {
    token: "",
    isLoggedIn: false,
  },
  effects: [localStorageEffect(localStorageKey)],
});

const setAuthDataAndLocalStorage = (setter: any, newValue: any) => {
  setter(newValue);
  localStorage.setItem(localStorageKey, JSON.stringify(newValue));
};

export const useAuthDataState = () => useRecoilState(authDataState);
export const useSetAuthDataState = () => {
  const setAuthData = useSetRecoilState(authDataState);
  return (newValue: any) => {
    setAuthDataAndLocalStorage(setAuthData, newValue);
  };
};
export const useResetAuthDataState = () => {
  const resetAuthDataState = useResetRecoilState(authDataState);
  return () => {
    resetAuthDataState();
    removeFromLocalStorage(localStorageKey);
  };
};
export const getToken = (): string | null => {
  const authState = localStorage.getItem(localStorageKey);
  if (authState) {
    const parsedState = JSON.parse(authState);
    return parsedState.token;
  }
  return null;
};
