import React from "react";
import { useCartDataState, useResetCartDataState } from "states/cartState";
import { useDealerSwitchState } from "states/dealerSwitchState";
import { showSuccessToast } from "utils/toast";

interface Product {
  id: number;
  title: string;
  price: number;
  quantity?: number;
  data: any[];
}

export default function useCartFunctions() {
  const [cart, setCart] = useCartDataState();
  const resetCart = useResetCartDataState();

  const addToCart = (item: Product): void => {
    setCart((prevCart) => {
      const existingItem = prevCart.items.find(
        (cartItem) => cartItem.id === item.id
      );

      let updatedItems;
      if (existingItem) {
        updatedItems = prevCart.items.map((cartItem) => {
          if (cartItem.id === item.id) {
            const newQuantity = (cartItem.quantity || 1) + 1;
            return {
              ...cartItem,
              quantity: newQuantity,
              subtotal: item.price * newQuantity,
              data: item.data,
            };
          }
          return cartItem;
        });
      } else {
        updatedItems = [
          ...prevCart.items,
          { ...item, quantity: 1, subtotal: item.price },
        ];
      }

      const updatedTotal = updatedItems.reduce(
        (sum, cartItem) => sum + cartItem.subtotal,
        0
      );

      showSuccessToast(`${item.title} added to cart`);

      return {
        total: updatedTotal,
        items: updatedItems,
      };
    });
  };
  const removeFromCart = (id: number): void => {
    setCart((prevCart) => {
      const existingItem = prevCart.items.find(
        (cartItem) => cartItem.id === id
      );

      let updatedItems;
      if (existingItem) {
        if ((existingItem.quantity || 1) > 1) {
          updatedItems = prevCart.items.map((cartItem) => {
            if (cartItem.id === id) {
              const newQuantity = (cartItem.quantity || 1) - 1;
              return {
                ...cartItem,
                quantity: newQuantity,
                subtotal: cartItem.price * newQuantity,
                data: cartItem.data,
              };
            }
            return cartItem;
          });
        } else {
          updatedItems = prevCart.items.filter(
            (cartItem) => cartItem.id !== id
          );
        }
      } else {
        updatedItems = prevCart.items;
      }

      const updatedTotal = updatedItems.reduce(
        (sum, cartItem) => sum + cartItem.subtotal,
        0
      );

      return {
        total: updatedTotal,
        items: updatedItems,
      };
    });
  };
  const clearCart = (): void => {
    resetCart();
  };

  return { cart, addToCart, removeFromCart, clearCart };
}
