import {
  Box,
  CloseRounded,
  CustomIconButton,
  ProjectLogo,
} from "components/common";
import useFunctions from "layouts/main-layout/ts/useFunctions";

export default function DrawerHeader() {
  const { handleDrawerToggle } = useFunctions();
  return (
    <Box
      display="flex"
      alignItems="center"
      py={1}
      pl={2}
      position="absolute"
      top={0}
      height="calc(80px - 16px)"
      width="calc(100% - 16px)"
      bgcolor="black"
    >
      <CustomIconButton onClick={handleDrawerToggle} color="secondary">
        <CloseRounded />
      </CustomIconButton>
      <ProjectLogo style={{ width: "100px" }} />
    </Box>
  );
}
