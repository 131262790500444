import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { getToken } from "states/authState";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_PRODUCTION,
  // baseURL: "http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      const event = new Event("unauthorized");
      window.dispatchEvent(event);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
