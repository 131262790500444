import { Box, styled } from "components/common";
import Image from "assets/images/bceome-dealer.png";

export const BecomeDealerLayoutWrapper = styled(Box)(({ theme }) => ({
  "& .nav": {
    position: "absolute",
    height: "100px",
    display: "flex",
    alignItems: "center",
    width: `calc(50% - ${theme.spacing(3)})`,
    paddingLeft: theme.spacing(3),
    zIndex: 10,
    background: "black",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(2)})`,
    },

    "& .left": {
      width: "100%",

      "& .logo": {
        cursor: "pointer",
        width: "125px",
        height: "auto",
      },
    },
  },
  "& .grid-cont": {
    height: "100vh",
    "& .left": {
      position: "relative",
      height: "100%",
      paddingTop: "100px",
      backgroundColor: "black",
      display: "flex",
      alignItems: "center",
    },
    "& .right": {
      backgroundImage: `url(${Image})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: theme.palette.grey[500],
    },
  },
  "& .mobile-top": {
    display: "none",
  },
}));
