//@ts-nocheck
import { TextField } from "@mui/material";
import { CustomInput, CustomPasswordInput } from "components/common";
import { useFormikContext } from "formik";
import { getFormikValue } from "utils/getFormikValue";
import {
  emailfieldName,
  firstfieldName,
  lastfieldName,
  passwordfieldName,
  phonefieldName,
} from "./ts/constants";
import { forwardRef } from "react";
import MaskedInput from "react-text-mask";
import { phoneMask } from "constants/masks";

export function FirstName() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, firstfieldName);
  };
  return (
    <TextField
      id={firstfieldName}
      name={firstfieldName}
      label="First Name"
      sx={{ width: "calc(50% - 8px)", mr: 1 }}
      margin="dense"
      variant="filled"
      size="small"
      value={getValue()}
      onChange={handleChange}
      error={errors[firstfieldName] && touched[firstfieldName]}
      helperText={
        errors[firstfieldName] &&
        touched[firstfieldName] &&
        errors[firstfieldName]
      }
    />
  );
}

export function LastName() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, lastfieldName);
  };
  return (
    <TextField
      id={lastfieldName}
      name={lastfieldName}
      label="Last Name"
      sx={{ width: "calc(50% - 8px)", ml: 1 }}
      margin="dense"
      variant="filled"
      size="small"
      value={getValue()}
      onChange={handleChange}
      error={errors[lastfieldName] && touched[lastfieldName]}
      helperText={
        errors[lastfieldName] && touched[lastfieldName] && errors[lastfieldName]
      }
    />
  );
}

const PhoneTextMask = forwardRef((props) => {
  return (
    <MaskedInput {...props} placeholder="(000) 000-0000" mask={phoneMask} />
  );
});

export function PhoneNumber() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, phonefieldName);
  };
  return (
    <TextField
      id={phonefieldName}
      name={phonefieldName}
      label="Phone Number"
      fullWidth
      margin="dense"
      variant="filled"
      size="small"
      type="tel"
      InputProps={{
        inputComponent: PhoneTextMask,
      }}
      value={getValue()}
      onChange={handleChange}
      error={errors[phonefieldName] && touched[phonefieldName]}
      helperText={
        errors[phonefieldName] &&
        touched[phonefieldName] &&
        errors[phonefieldName]
      }
    />
  );
}

export function EmailAddress() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, emailfieldName);
  };
  return (
    <TextField
      id={emailfieldName}
      name={emailfieldName}
      label="Email Address"
      fullWidth
      margin="dense"
      variant="filled"
      size="small"
      value={getValue()}
      onChange={handleChange}
      error={errors[emailfieldName] && touched[emailfieldName]}
      helperText={
        errors[emailfieldName] &&
        touched[emailfieldName] &&
        errors[emailfieldName]
      }
    />
  );
}

export function Password() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, passwordfieldName);
  };
  return (
    <CustomPasswordInput
      id={passwordfieldName}
      name={passwordfieldName}
      label="Password"
      margin="dense"
      fullWidth
      variant="filled"
      size="small"
      value={getValue()}
      onChange={handleChange}
      error={errors[passwordfieldName] && touched[passwordfieldName]}
      helperText={
        errors[passwordfieldName] &&
        touched[passwordfieldName] &&
        errors[passwordfieldName]
      }
    />
  );
}
