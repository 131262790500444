import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { forwardRef, useState } from "react";
import MaskedInput from "react-text-mask";
import { CustomDatePickerProps, OtherPros } from "utils/interface";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { phoneMask } from "constants/masks";

export function CustomInput(props: TextFieldProps & OtherPros) {
  const { id, className = "" } = props;

  return (
    <TextField
      name={id}
      label={props.label}
      variant="outlined"
      className={className}
      fullWidth
      margin="normal"
      {...props}
    />
  );
}
export function CustomPasswordInput(props: TextFieldProps & OtherPros) {
  const { className = "" } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <TextField
      label={props.label}
      className={className}
      fullWidth
      type={showPassword ? "text" : "password"}
      margin="normal"
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="start"
          >
            {showPassword ? (
              <VisibilityOff className="stroke-[#fff] mr-4" />
            ) : (
              <Visibility className="stroke-[#fff] mr-4" />
            )}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
}

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  return <MaskedInput {...props} placeholder="" mask={phoneMask} />;
});

TextMaskCustom.displayName = "TextMaskCustom";

export function CustomPhoneNumberInput(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      className="custom-input "
      fullWidth
      margin="normal"
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
    />
  );
}

export function CustomDatePicker<TDate extends Moment>(
  props: CustomDatePickerProps<TDate>
) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker {...props} />
    </LocalizationProvider>
  );
}
