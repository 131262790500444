import { Box, Typography } from "@mui/material";
import AccordianBonce from "components/accordian-bonce";
import AccordionComponent from "components/accordian-daudio";
import AccordionNemisis from "components/accordian-nemisis";
import AccordionSundown from "components/accordian-sundown";
import AccordionXSpower from "components/accordian-xs-power";
import { Dispatch, ReactNode, SetStateAction } from "react";

export interface DropDownProps {
  open: boolean;
  children: ReactNode;
  index: number;
}

export interface NavbarOptionsProps {
  label: string;
  variant: "contained" | "text" | "outlined";
  children?: ReactNode;
  href?: string;
  type: "modal" | "link" | "node";
}

export interface HeaderDesktopLinksItemProps {
  item: NavbarOptionsProps;
  index: number;
  handleDropDownToggle: (data: NavbarOptionsProps, index: number) => void;
}

export const navbarOptions: NavbarOptionsProps[] = [
  {
    label: "Bulb Finder",
    variant: "contained",
    type: "modal",
  },
  {
    label: "Deaf Bonce",
    variant: "text",
    children: <AccordianBonce />,
    type: "node",
  },

  {
    label: "DD Audio",
    variant: "text",
    children: <AccordionComponent />,
    type: "node",
  },

  {
    label: "Sundown Audio",
    variant: "contained",
    children: <AccordionSundown />,
    type: "node",
  },
  {
    label: "XS Power Batteries",
    variant: "text",
    children: <AccordionXSpower />,
    type: "node",
  },
  {
    label: "Nemesis Audio",
    variant: "text",
    children: <AccordionNemisis />,
    type: "node",
  },
  {
    label: "Contact",
    variant: "text",
    href: "/contact-us",
    type: "link",
  },
];
