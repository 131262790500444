// src/utils/toast.ts
import {
  toast,
  ToastContainer,
  ToastOptions,
  UpdateOptions,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultOptions: ToastOptions = {
  position: "bottom-left",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};
export const showLoadingToast = (message: string) => {
  return String(toast.loading(message, defaultOptions));
};
export const updateToast = (id: string, properties: UpdateOptions) => {
  toast.update(id, { ...defaultOptions, ...properties });
};
export const showToast = (message: string, options?: ToastOptions) => {
  toast(message, { ...defaultOptions, ...options });
};

export const showSuccessToast = (message: string, options?: ToastOptions) => {
  toast.success(message, { ...defaultOptions, ...options });
};

export const showErrorToast = (message: string, options?: ToastOptions) => {
  toast.error(message, { ...defaultOptions, ...options });
};

export const showInfoToast = (message: string, options?: ToastOptions) => {
  toast.info(message, { ...defaultOptions, ...options });
};

export const showWarningToast = (message: string, options?: ToastOptions) => {
  toast.warn(message, { ...defaultOptions, ...options });
};

export const showPromiseToast = (
  promise: Promise<any>,
  messages: { loading: string; success: string; error: string }
) => {
  toast.promise(
    promise,
    {
      pending: messages.loading,
      success: messages.success,
      error: messages.error,
    },
    defaultOptions
  );
};
export const ToastProvider: React.FC = () => <ToastContainer />;
