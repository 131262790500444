import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const CustomLinkWrapper = styled(Link)(({ theme }) => ({
  display: "block",
  color: "white",
  textDecoration: "none",
  ":hover": {
    color: theme.palette.primary.main,
  },
}));
