import { Box } from "components/common";
import Navigation from "./navigation";

const DrawerContent = () => (
  <Box
    mt="100px"
    height="100%"
    sx={{
      overflowY: "auto",
      overflowX: "hidden",
    }}
  >
    <Navigation />
  </Box>
);

export default DrawerContent;
